import {
  MenuFoldOutlined,
  MenuUnfoldOutlined,
  UploadOutlined,
  UserOutlined,
  VideoCameraOutlined,
} from "@ant-design/icons";
import { Layout, Menu } from "antd";
import React, { useState } from "react";
// import { useMoralis } from "react-moralis";

const { Header, Sider, Content } = Layout;

const Admin = () => {
  // const { isAuthenticated } = useMoralis();
  const [collapsed, setCollapsed] = useState(false);

  const toggle = () => {
    if (collapsed === false) setCollapsed(true);
    if (collapsed === true) setCollapsed(false);
  };

  return (
    <Layout>
      <Sider trigger={null} collapsible collapsed={collapsed}>
        <div className="logo" />
        <Menu theme="dark" mode="inline" defaultSelectedKeys={["1"]}>
          <Menu.Item key="1" icon={<UserOutlined />}>
            Pharos
          </Menu.Item>
          <Menu.Item key="2" icon={<VideoCameraOutlined />}>
            Liquidity
          </Menu.Item>
          <Menu.Item key="3" icon={<UploadOutlined />}>
            Cover
          </Menu.Item>
        </Menu>
      </Sider>
      <Layout className="site-layout">
        <Header className="site-layout-background" style={{ paddingLeft: 5, float: "left", marginTop: -10 }}>
          {React.createElement(collapsed ? MenuUnfoldOutlined : MenuFoldOutlined, {
            className: "trigger",
            onClick: toggle,
          })}
        </Header>
        <Content
          className="site-layout-background"
          style={{
            margin: "24px 16px",
            padding: 24,
            minHeight: 280,
          }}
        >
          What do we want to do on our admin page?
          <br />
          1. <br />
          2. <br />
          3. <br />
        </Content>
      </Layout>
    </Layout>
  );
};

export default Admin;
