import { Col, Row } from "antd";
import React from "react";
import { Link } from "react-router-dom";
// import { useMoralis } from "react-moralis";

const Home = (...props) => {
  // const { isAuthenticated } = useMoralis();
  return (
    <div className="h-screen">
      <Row>
        <Col span={12}>
          <Link to="/buy-cover">
            <button className="text-3xl h-16 flex text-gray-100 font-bold justify-center transition duration-200 ease-in-out transform px-4 py-2 w-48 border-b-4 border-gray-500 hover:border-b-2 bg-gradient-to-t from-gray-400  via-gray-600 to-gray-200 rounded-2xl hover:translate-y-px w-full -ml-12">
              Buy Cover
            </button>
          </Link>
        </Col>
        <Col span={12}>
          <Link to="/">
            <button className="text-3xl h-16 float-right flex text-gray-900 font-bold justify-center transition duration-200 ease-in-out transform px-4 py-2 w-48 border-b-4 border-gray-500 hover:border-b-2 bg-gradient-to-t from-gray-400  via-gray-600 to-gray-200 rounded-2xl hover:translate-y-px w-full -mr-12">
              About
            </button>
          </Link>
        </Col>
      </Row>
      <Row>
        <Col span={12}>
          {/* <Link to="/provide-liquidity"> */}
          <Link to="/">
            <button className="text-3xl h-16 mt-12 flex text-gray-900 font-bold justify-center transition duration-200 ease-in-out transform px-4 py-2 w-48 border-b-4 border-gray-500 hover:border-b-2 bg-gradient-to-t from-gray-400  via-gray-600 to-gray-200 rounded-2xl hover:translate-y-px w-full -ml-12">
              Provide liquidity
            </button>
          </Link>
        </Col>
        <Col span={12}>
          {true &&
            // <Link to="/profile">
            <Link to="/">
              <button className="text-3xl h-16 mt-12 float-right flex text-gray-900 font-bold justify-center transition duration-200 ease-in-out transform px-4 py-2 w-48 border-b-4 border-gray-500 hover:border-b-2 bg-gradient-to-t from-gray-400  via-gray-600 to-gray-200 rounded-2xl hover:translate-y-px w-full -mr-12">
                Account
              </button>
            </Link>
          }
        </Col>
      </Row>
      <Row>
        <Col span={12}>
          {/* <Link to="/create-market"> */}
          <Link to="/">
            <button className="text-3xl h-16 mt-12 flex text-gray-900 font-bold justify-center transition duration-200 ease-in-out transform px-4 py-2 w-48 border-b-4 border-gray-500 hover:border-b-2 bg-gradient-to-t from-gray-400  via-gray-600 to-gray-200 rounded-2xl hover:translate-y-px w-full -ml-12">
              Create Market
            </button>
          </Link>
        </Col>
        <Col span={12}>
          <div className="mr-8 mt-12 float-right flex text-gray-100 justify-center transition duration-200 ease-in-out transform px-8 py-2 w-48 border-b-4 border-gray-500 bg-gradient-to-t from-gray-400  via-gray-600 to-gray-200 rounded-2xl">
            <span class="inline-flex sm:ml-auto sm:mt-0 mt-4 justify-center sm:justify-start">
              <a disabled href="/" className="bg-black rounded-full p-2">
                <svg
                  fill="#ffffff"
                  strokeLinecap="round"
                  strokeLinejoin="round"
                  strokeWidth="2"
                  class="w-5 h-5"
                  viewBox="0 0 24 24"
                >
                  <path d="M18 2h-3a5 5 0 00-5 5v3H7v4h3v8h4v-8h3l1-4h-4V7a1 1 0 011-1h3z"></path>
                </svg>
              </a>
              <a disabled href="/" className="ml-3 bg-black rounded-full p-2">
                <svg
                  fill="#ffffff"
                  strokeLinecap="round"
                  strokeLinejoin="round"
                  strokeWidth="2"
                  class="w-5 h-5"
                  viewBox="0 0 24 24"
                >
                  <path d="M23 3a10.9 10.9 0 01-3.14 1.53 4.48 4.48 0 00-7.86 3v1A10.66 10.66 0 013 4s-4 9 5 13a11.64 11.64 0 01-7 2c9 5 20 0 20-11.5a4.5 4.5 0 00-.08-.83A7.72 7.72 0 0023 3z"></path>
                </svg>
              </a>
              <a disabled href="/" className="ml-3 bg-black rounded-full p-2">
                <svg
                  fill="none"
                  stroke="#ffffff"
                  strokeLinecap="round"
                  strokeLinejoin="round"
                  strokeWidth="2"
                  class="w-5 h-5"
                  viewBox="0 0 24 24"
                >
                  <rect width="20" height="20" x="2" y="2" rx="5" ry="5"></rect>
                  <path d="M16 11.37A4 4 0 1112.63 8 4 4 0 0116 11.37zm1.5-4.87h.01"></path>
                </svg>
              </a>
              <a disabled href="/" className="ml-3 bg-black rounded-full p-2">
                <svg
                  fill="#ffffff"
                  stroke="currentColor"
                  strokeLinecap="round"
                  strokeLinejoin="round"
                  strokeWidth="0"
                  class="w-5 h-5"
                  viewBox="0 0 24 24"
                >
                  <path
                    stroke="none"
                    d="M16 8a6 6 0 016 6v7h-4v-7a2 2 0 00-2-2 2 2 0 00-2 2v7h-4v-7a6 6 0 016-6zM2 9h4v12H2z"
                  ></path>
                  <circle cx="4" cy="4" r="2" stroke="none"></circle>
                </svg>
              </a>
            </span>
          </div>
        </Col>
      </Row>
    </div>
  );
};

export default Home;
