import React from "react";
import { Col, Dropdown, Row, Menu } from "antd";
import { Link } from "react-router-dom";
import Account from "./Account";
import { MenuOutlined } from "@ant-design/icons";

import pharoLogo2 from "../assets/pharo_logo_2.png";

const menu = (
    <Menu>
      <Menu.Item key="1">
        <Link to="/buy-cover">
          Buy Cover
        </Link>
      </Menu.Item>
      <Menu.Item key="2">
        <Link to="/about">
          About
        </Link>
      </Menu.Item>
      <Menu.Item key="3">
        <Link to="/provide-liquidity">
          Provide liquidity
        </Link>
      </Menu.Item>
      <Menu.Item key="4">
        <Link to="/create-market">
          Create Market
        </Link>
      </Menu.Item>
    </Menu>
  );

export default function Nav(props) {
  return (
	<Row className="p-10">
		<Col span={8}>
		<Link to="/">
			<img src={pharoLogo2} alt="Pharo Logo" className="h-20 w-20"/>
		</Link>
		</Col>
		<Col span={8}>
		{props.pageTitle ? props.pageTitle : ""}
		{props.pageSubTitle ? props.pageSubTitle : ""}
		</Col>
		<Col span={8}>
			<div className="float-right flex text-gray-100 font-bold justify-center transition duration-200 ease-in-out transform px-4 py-2 w-48 border-b-4 border-gray-500 bg-gradient-to-t from-gray-400  via-gray-600 to-gray-200 rounded-2xl w-full -mr-12">
            	<div className="inline-flex">
					<Account
						address={props.address}
						localProvider={props.localProvider}
						userSigner={props.userSigner}
						mainnetProvider={props.mainnetProvider}
						price={props.price}
						web3Modal={props.web3Modal}
						loadWeb3Modal={props.loadWeb3Modal}
						logoutOfWeb3Modal={props.logoutOfWeb3Modal}
						blockExplorer={props.blockExplorer}
						networkDisplay={props.networkDisplay}
					/>
					{props.faucetHint}
				</div>
                <div className="ml-4 inline-flex">
					<Dropdown overlay={menu} trigger="click" placement="bottomRight" className="px-4 py-4 rounded-lg border-2 border-gray-100 px-4 py-4 rounded-lg align-middle">
						<MenuOutlined/>
					</Dropdown>
				</div>
			</div>
		</Col>
	</Row>
  );
};
