import { ReloadOutlined } from "@ant-design/icons";
import { Button, Col, Drawer, Input, notification, Row, Space, Tabs, Modal } from "antd";
import axios from "axios";
import { ethers } from "ethers";
import React, { useEffect, useState } from "react";
import { CSVLink } from "react-csv";
import advancedTitle from "../assets/buy_cover/advanced_title.svg";
import buyCoverTitle from "../assets/buy_cover/buy_cover_title.svg";
import line from "../assets/buy_cover/line.png";
import { BarChart, AreaChart, Nav } from "../components";
import { LINKAGGV3ABI } from "../constants";

const feedProvider = new ethers.providers.JsonRpcProvider(
  "https://speedy-nodes-nyc.moralis.io/43d22b430189c5a5c63506a7/polygon/mumbai",
);
const feedAggAddress = "0x12162c3E810393dEC01362aBf156D7ecf6159528";
const priceFeed = new ethers.Contract(feedAggAddress, LINKAGGV3ABI, feedProvider);

const BuyCover = ({
  tx,
  readContracts,
  writeContracts,
  kovanContracts,
  address,
  localProvider,
  userSigner,
  mainnetProvider,
  price,
  web3Modal,
  loadWeb3Modal,
  logoutOfWeb3Modal,
  blockExplorer,
  networkDisplay,
  faucetHint,
}) => {
  const [visible, setVisible] = useState(false);
  const [data, setData] = useState();
  const [rawData, setRawData] = useState("");
  const [token, setToken] = useState();
  const [stakeAmount, setStakeAmount] = useState(0);
  const [phroRewards, setPhroRewards] = useState(0);
  const [graphData, setGraphData] = useState(0);
  const [phroBalance, setPhroBalance] = useState(0);
  const [allowance, setAllowance] = useState(0);
  const [coverPolicyDetails, setCoverPolicyDetails] = useState({});
  const [rateEstimate, setRateEstimate] = useState(0);
  const [minimumCoverAcceptedFor, setMinimumCoverAcceptedFor] = useState(0);
  const [user, setUser] = useState({
      pharoId: 0,
      username: "testuser",
      walletId: address,
      stake: stakeAmount,
      reward: phroRewards,
      rateEstimate: rateEstimate,
  });
  const [isUserAddModalVisible, setIsUserAddModalVisible] = useState(false);

  const showUserAddModal = () => {
    setIsUserAddModalVisible(true);
  };

  const handleOk = () => {
    setIsUserAddModalVisible(false);
  };

  const handleCancel = () => {
    setIsUserAddModalVisible(false);
  };


  // CoverPolicyStatus to mimic what comes back from the contract => getCoverPolicyDetails(policyId)
  const coverPolicyStatus = {
    0: "INITIALIZING",
    1: "ACTIVE",
    2: "INACTIVE",
    3: "PENDING",
    4: "CLOSED",
    5: "OPEN",
    6: "REJECTED",
    7: "CLOSEDPAID",
    8: "CLOSEDUNPAID",
  };

  const getLatestPrice = async () => {
    await priceFeed.latestRoundData().then(roundData => {
      // do something with the data
      console.log("Price Feed Data: ", ethers.utils.formatEther(roundData.answer.toString()), " Matic per Link token.");
    });
  };

  // getLatestPrice();

  const onClose = () => {
    setVisible(false);
  };

  const showDrawer = () => {
    setVisible(true);
  };

  // Get user balance of PHRO
  async function getPhroBalance() {
    (await readContracts) &&
      readContracts.PHROToken &&
      readContracts.PHROToken.balanceOf(address).then(bal => {
        setPhroBalance(bal);
      });
  }
  async function getAllowance() {
    (await readContracts) &&
      readContracts.PHROToken &&
      readContracts.PHROToken.allowance(
        address,
        readContracts && readContracts.PharoCover && readContracts.PharoCover.address,
      ).then(allow => {
        setAllowance(allow);
      });
  }
  // Update the allowance and balance on address change only
  // todo: update every n blocks
  useEffect(() => {
    getPhroBalance();
    getAllowance();
  }, [address, stakeAmount]);

  useEffect(() => {
    console.log("data start");
    if (data) {
      //Set BarChart Data
      let barchart = [];
      let graphLength = data.rateEstimatesX.length;
      let maxHeight = Math.max(...data.gammaCurveY);
      for (let i = 0; i < graphLength; i++) {
        let tempObj = {
          x_axis: data.rateEstimatesX[i],
          "Rate Estimates": data.rateEstimatesY[i],
        };
        if (i >= data.minConfidence && i <= data.maxConfidence) {
          tempObj["Confidence Interval"] = maxHeight;
          tempObj.confidence_interval_x = i;
        }
        barchart.push(tempObj);
      }

      //Set AreaChart Data
      let areachart = [];
      graphLength = data.gammaCurveX.length;
      maxHeight = Math.max(...data.gammaCurveY);
      for (let i = 0; i < graphLength; i++) {
        let tempObj = {
          x_axis: data.gammaCurveX[i],
          "Gamma Curve": data.gammaCurveY[i],
        };
        if (i >= data.minConfidence && i <= data.maxConfidence) {
          tempObj["Confidence Interval"] = maxHeight;
          tempObj.confidence_interval_x = i;
        }
        areachart.push(tempObj);
      }

      setGraphData({ barchart: barchart, areachart: areachart });
    }
  }, [data]);

  console.log("PHRO Balance: ", ethers.utils.formatEther(phroBalance.toString()));
  console.log("Allowance for Cover contract: ", ethers.utils.formatEther(allowance.toString()));

  // Buy a cover policy
  const buyCoverPolicy = async () => {
    if (stakeAmount !== 0 || stakeAmount !== "undefined") {
      setToken(readContracts && readContracts.PHROToken && readContracts.PHROToken.address);
      console.log("Sending transaction to buy a policy");
      await tx(
        writeContracts &&
          writeContracts.PharoCover &&
          writeContracts.PharoCover.createCoverPolicy(
            ethers.utils.parseEther(minimumCoverAcceptedFor.toString()), // minimum cover
            ethers.utils.parseEther(stakeAmount.toString()), // stake amount
            rateEstimate, // rate estimate
            300000, // length of cover in seconds
            address && address, // the cover buyers address
            token, // the token used to pay for cover, will be GTC
            0, // the PharoId, we just mock 0 for now
          ),
        async update => {
          console.log("buyCoverPolicy Update:", update);
          if (update.status === 1) {
            notification.open({
              message: "Policy Status",
              description: "Your Policy is Now Acitve",
            });
            getAllowance();
            getPhroBalance();
            setStakeAmount(0);
            setPhroRewards(0);
            setRateEstimate(0);
            setMinimumCoverAcceptedFor(0);
          }
        },
      );
    }
  };

  // Approve cover contract to spend users crypto
  const approveCoverContract = async amount => {
    console.log("Approving buyers token to buy policy");
    await tx(
      writeContracts &&
        writeContracts.PHROToken &&
        writeContracts.PHROToken.approve(
          readContracts && readContracts.PharoCover && readContracts.PharoCover.address,
          ethers.utils.parseEther(amount.toString()),
        ),
      async update => {
        console.log("📡 Transaction Update:", update);
        if (update.status === 1) {
          await getQuote();
          notification.open({
            message: "Token Allowance",
            description: "Your tokens have been approved",
          });
          getAllowance();
          getPhroBalance();
        }
      },
    );
  };

  // Get the cover policy details for UI
  async function getCoverPolicyDetails(policyId) {
    (await readContracts) &&
      readContracts.PharoCover &&
      readContracts.PharoCover.getCoverPolicyDetails(policyId).then(e => {
        console.table(e);
        setCoverPolicyDetails(e);
      });
  }

  // get the details for policy id 2 for testing
  // getCoverPolicyDetails(2);  

  const addUser = async () => {
    await axios(`https://pharoapi.herokuapp.com/user/create/`, {
      method: "POST",
      mode: "no-cors",
      headers: {
        "Access-Control-Allow-Origin": "*",
        "Content-Type": "application/json",
        "accept": "application/json",
      },
      withCredentials: true,
      credentials: "same-origin",
      data: JSON.stringify(user),
    })
      .then(response => {
        console.log("getQuote data ", response);
        setUser(response.data);
      })
      .catch(error => console.log(error));
  };

  const getQuote = async () => {
    // addUser();
    await axios(`https://pharoapi.herokuapp.com/market/quote/?stake=${stakeAmount}&rate_est=${rateEstimate}`, {
      method: "GET",
      mode: "no-cors",
      headers: {
        "Access-Control-Allow-Origin": "*",
        "Content-Type": "application/json",
      },
      withCredentials: true,
      credentials: "same-origin",
    })
      .then(response => {
        console.log("getQuote data ", response && response.data);
        if (response?.data) {
          setPhroRewards(response.data.reward);
          setStakeAmount(response.data.price);
          setMinimumCoverAcceptedFor(response.data.cover);
        }
      })
      .catch(error => console.log(error));
  };

  const getObeliskData = async () => {
    await axios("https://pharoapi.herokuapp.com/market/obelisk/", {
      method: "GET",
      mode: "no-cors",
      headers: {
        "Access-Control-Allow-Origin": "*",
        "Content-Type": "application/json",
      },
      withCredentials: true,
      credentials: "same-origin",
    })
      .then(response => {
        console.log("data ", response && response.data);
      })
      .catch(error => console.log(error));
  };

  // Fetches the woc data from api mock-up for front-end mock-up
  const getWocData = async () => {
    await axios("https://pharoapi.herokuapp.com/market/woc/", {
      method: "GET",
      mode: "no-cors",
      headers: {
        "Access-Control-Allow-Origin": "*",
        "Content-Type": "application/json",
      },
      withCredentials: true,
      credentials: "same-origin",
    })
      .then(response => {
        console.log("data ", response && response.data);
        if (response && response.data) {
          const result = response.data;
          setData(result);
          //Format CSV Output
          const str = Object.keys(result)
            .map(function (key) {
              return "" + key + "=" + data[key]; // line break for wrapping only
            })
            .join(", ");
          setRawData(str);
          console.log("raw data ", rawData, data);
        }
      })
      .catch(error => console.log(error));
  };

  return (
    <div className="h-screen">
      <Nav
        address={address}
        localProvider={localProvider}
        userSigner={userSigner}
        mainnetProvider={mainnetProvider}
        price={price}
        web3Modal={web3Modal}
        loadWeb3Modal={loadWeb3Modal}
        logoutOfWeb3Modal={logoutOfWeb3Modal}
        blockExplorer={blockExplorer}
        networkDisplay={networkDisplay}
        faucetHint={faucetHint}
        pageTitle={<img src={buyCoverTitle} alt="Buy Cover Title" className="h-auto w-auto" />}
      />
      <Row>
        <Col span={24}>
          <div className="flex items-center z-0">
            <div className="border-4 border-yellow-gold px-4 py-4 rounded-t-lg bg-black mx-auto text-4xl text-white">
              SHIB Price Coverage
            </div>
          </div>
        </Col>
      </Row>
      {/* <Row>
        <Col span={24}>
          <div className="flex items-center">
            <div className="mt-4 border-4 border-yellow-gold px-4 py-6 rounded-b-lg bg-black mx-auto text-2xl">
              YOUR QUESTION: How many points will the token swing (+/-) by?
            </div>
          </div>
        </Col>
      </Row> */}
      <Row>
        <Col span={12}>
          <div className="m-8 border-4 border-yellow-gold bg-opacity-50 px-4 py-6 rounded-lg bg-brown-light-brown">
            <div className="-mt-12 border-4 border-yellow-gold px-4 py-6 rounded-lg bg-black text-2xl font-zillaslab text-yellow-gold">
              YOUR POSITION
            </div>
            <div className="mt-10 border-4 border-yellow-gold px-4 py-6 rounded-lg bg-black">
              <div className="text-base mb-6 text-white">
                Your Stake:{" "}
              </div>
              <div className="p-6">
                <Input
                  value={stakeAmount}
                  onChange={e => {
                    setStakeAmount(e.target.value);
                  }}
                />
              </div>
            </div>
            <div className="mt-10 border-4 border-yellow-gold px-4 py-6 rounded-lg bg-black">
              <div className="text-base mb-6 text-white">
              In how many days will SHIB fall by 15% or more?{" "}
              </div>
              <div className="p-6">
                <Input
                  value={rateEstimate}
                  onChange={e => {
                    setRateEstimate(e.target.value);
                  }}
                />
              </div>
            </div>
            {allowance <= stakeAmount ? (
              <button
                className="mt-10 border-4 border-white px-4 py-6 rounded-full bg-black text-xl font-zillaslab text-white rounded"
                onClick={() => approveCoverContract(stakeAmount)}
              >
                APPROVE
              </button>
            ) : (
              <button
                className="mt-10 border-4 border-white px-4 py-6 rounded-full bg-black text-2xl font-zillaslab text-white rounded"
                onClick={() => buyCoverPolicy(stakeAmount)}
              >
                BUY COVER
              </button>
            )}
            {" "}
            <button
                className="mt-10 border-4 border-white px-4 py-6 rounded-full bg-black text-xl font-zillaslab text-white rounded"
                onClick={() => getQuote()}
              >
                REFRESH REWARDS
              </button>
          </div>
        </Col>
        {/* todo: mocked up for now */}
        <Col span={12}>
          <div className="m-8 border-4 border-yellow-gold bg-opacity-50 px-4 py-6 rounded-lg bg-brown-light-brown">
            <div className="-mt-12 border-4 border-yellow-gold px-4 py-6 rounded-lg bg-black text-2xl font-zillaslab text-yellow-gold">
              YOUR BENEFITS
            </div>
            <div className="mt-10 border-4 border-yellow-gold px-4 py-6 rounded-lg bg-blue-dark-sky-blue">
              <span>{phroRewards}</span>
              <span className="m-5">PHRO REWARDS</span>
            </div>
            <div className="mt-10 border-4 border-yellow-gold px-4 py-6 rounded-lg bg-blue-dark-sky-blue">
              Amount of Cover Approved:{" "}
              <Input
                className="m-5"
                value={minimumCoverAcceptedFor}
                onChange={e => {
                  setMinimumCoverAcceptedFor(e.target.value);
                }}
              />
            </div>
          </div>
        </Col>
      </Row>
      {/* Simple Panel ^^^^^^^^^^ */}
      {/* Advanced Panel vvvvvvvvv */}
      <Row>
      <div class="border-4 border-yellow-gold rounded-full w-full mr-6 ml-6">
        <button
          className="float-right px-4 py-4 rounded-3xl bg-green-jade"
          onClick={showDrawer}
        >
          <img src={advancedTitle} alt="Advanced Title" />
        </button>
      </div>
      </Row>
      <Drawer
        placement={"bottom"}
        onClose={onClose}
        visible={visible}
        width="100vh"
        height="100vh"
        extra={
          <Space>
            <Button onClick={onClose}>Cancel</Button>
            <Button type="primary" onClick={onClose}>
              OK
            </Button>
          </Space>
        }
      >
        <div className="bg-blueYellowBackground p-0 h-screen">
          <Nav
            address={address}
            localProvider={localProvider}
            userSigner={userSigner}
            mainnetProvider={mainnetProvider}
            price={price}
            web3Modal={web3Modal}
            loadWeb3Modal={loadWeb3Modal}
            logoutOfWeb3Modal={logoutOfWeb3Modal}
            blockExplorer={blockExplorer}
            networkDisplay={networkDisplay}
            faucetHint={faucetHint}
            pageTitle={<img src={buyCoverTitle} alt="Buy Cover Title" className="h-auto w-auto" />}
          />
          <Row>
            <Col span={16}>
              <div className="flex flex-row m-8 border-4 border-yellow-gold bg-opacity-40 px-4 py-6 rounded-lg bg-brown-light-brown">
                <div className="flex flex-row">
                  <div className="-mt-12 container">
                    <div className="h-auto border-4 border-yellow-gold px-4 py-6 rounded-lg bg-black font-zillaslab text-xl text-white">
                      Estimate and Maximize Rewards
                    </div>
                  </div>
                  <button
                    className="ml-2 w-20 h-20 -mt-12 border-4 border-yellow-gold rounded-lg bg-black text-white"
                    onClick={getWocData}
                  >
                    Refresh View
                  </button>
                </div>
                <div className="flex flex-row w-full">
                  <div className="mt-10 p-6 min-w-full">
                    <Tabs defaultActiveKey="1" centered>
                      <Tabs.TabPane tab="Rate Estimates" key="1">
                        {data && graphData ? (
                        <div>
                          <BarChart data={graphData.barchart} />
                        </div>
                      ) : (
                        ""
                      )}
                      </Tabs.TabPane>
                      <Tabs.TabPane tab="Gamma Curve" key="2">
                      {data && graphData ? (
                        <div>
                          <AreaChart data={graphData.areachart} />
                        </div>
                      ) : (
                        ""
                      )}
                      </Tabs.TabPane>
                    </Tabs>
                  </div>
                </div>
              </div>

              <Row>
                <button className="ml-8 border-4 border-yellow-gold px-4 py-4 rounded-lg bg-black">
                  <CSVLink data={rawData} filename={`pharo-buy-cover-raw-data-${Date.now()}.csv`}>
                    GET RAW DATA
                  </CSVLink>
                </button>
                <button
                  className="ml-8 border-4 border-yellow-gold px-4 py-4 rounded-lg bg-black text-white"
                  onClick={() => {
                    onClose();
                  }}
                >
                  SIMPLE VIEW
                </button>
              </Row>
            </Col>
            {/* Can */}
            <Col span={8}>
              <div className="m-8 border-4 border-yellow-gold bg-opacity-50 px-4 py-6 rounded-lg bg-brown-light-brown">
                <div className="-mt-12">
                  <div className="text-white text-center border-4 border-yellow-gold px-4 py-6 rounded-lg bg-black font-zillaslab text-2xl">
                  YOUR BENEFITS
                  </div>
                </div>
                <div className="mt-10 border-4 text-center border-yellow-gold px-4 py-6 rounded-lg bg-blue-dark-sky-blue">
                  <span>{phroRewards}</span>
                  <span className="m-5">PHRO REWARDS</span>
                </div>
                <div className="mt-10 border-4 text-center border-yellow-gold px-4 py-6 rounded-lg bg-blue-dark-sky-blue">
                  Minumum Cover Required:{" "}
                  <Input
                    className="m-5"
                    value={minimumCoverAcceptedFor}
                    onChange={e => {
                      setMinimumCoverAcceptedFor(e.target.value);
                    }}
                  />
                </div>
              </div>
            </Col>
          </Row>
        </div>
      </Drawer>
      <Modal
        title="Add User"
        visible={isUserAddModalVisible}
        onOk={() => handleOk()}
        onCancel={() => handleCancel()}
        okText="Add User"
        width="500px"
      >
        <div>
          <Row>
            <Col>
              <span>Username: </span>{" "}
              <Input onChange={(e) => {console.log(e.target.value)}} />
            </Col>
          </Row>
        </div>
      </Modal>
    </div>
  );
};

export default BuyCover;
